import React, { memo } from 'react';
import Contact from '../components/Contact';
import Layout from '../components/Layout';
import ScrollButton from '../components/ScrollButton';
import Seo from '../components/Seo';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const ContactPage = memo(() => {
  const { backgroundImage123 } = useStaticQuery(
    graphql`
      query {
        backgroundImage123: file(relativePath: { eq: "coffee.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, webpOptions: { quality: 100 })
          }
        }
      }
    `
  );
  const image = getImage(backgroundImage123);

  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      className="masthead3"
    >
      <Layout fullMenu>
        <Seo
          title="Contact us"
          description="Surge can be found in our of our two offices, by phone or online."
        />

        <ScrollButton />
        <article id="main">
          <header>
            <h2>Surge Consulting</h2>
            <p>Partnering with leaders to maximise value</p>
          </header>
          <section className="wrapper style5">
            <div className="inner">
              <Contact />
            </div>
          </section>
        </article>
      </Layout>
    </BackgroundImage>
  );
});

export default ContactPage;
