import React, { memo } from 'react';

const Contact = memo(() => {
  return (
    <section>
      <h4>LET'S GET IN TOUCH</h4>
      <form
        method="post"
        action="https://getform.io/f/05d9b67c-50e1-4a14-a41f-1541d98d2913"
      >
        <div className="row gtr-uniform">
          <div className="col-6 col-12-xsmall">
            <input
              type="text"
              name="name"
              id="name"
              defaultValue=""
              placeholder="Name"
            />
          </div>
          <div className="col-6 col-12-xsmall">
            <input
              type="email"
              name="email"
              id="email"
              defaultValue=""
              placeholder="Email"
            />
          </div>
          <div className="col-12">
            <select name="category" id="category">
              <option value="">- Category -</option>
              <option value="Project Delivery">Project Delivery</option>
              <option value="Agility">Business Transformation</option>
              <option value="Technology">Surge Technology</option>
            </select>
          </div>

          <div className="col-6 col-12-small">
            <input type="checkbox" id="access" name="access" defaultChecked />
            <label htmlFor="access">Early access to content</label>
          </div>

          <div className="col-12">
            <textarea
              name="message"
              id="message"
              placeholder="Enter your message"
              rows="6"
            ></textarea>
          </div>
          <div className="col-12">
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="primary" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </div>
        </div>
      </form>
    </section>
  );
});

export default Contact;
